@font-face {
  font-family: 'Arvo';
  font-style: normal;
  font-weight: 400;
  src: local('Arvo'), url(assets/fonts/Arvo.woff) format('woff');
}

@font-face {
  font-family: 'CrimsonText';
  font-style: normal;
  font-weight: 400;
  src: local('CrimsonText'), url(assets/fonts/CrimsonText.woff) format('woff');
}

@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: local('Karla'), url(assets/fonts/Karla.woff) format('woff');
}

@font-face {
  font-family: 'Literata';
  font-style: normal;
  font-weight: 400;
  src: local('Literata'), url(assets/fonts/Literata.woff) format('woff');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: local('Merriweather'), url(assets/fonts/Merriweather.woff) format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat'), url(assets/fonts/Montserrat.woff) format('woff');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  src: local('OpenSans'), url(assets/fonts/OpenSans.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), url(assets/fonts/Roboto.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto'), url(assets/fonts/Roboto-Medium.woff) format('woff');
}
